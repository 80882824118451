import { collection, CollectionReference, DocumentData } from 'firebase/firestore';
import { Outlet } from 'react-router-dom';
import { db } from '../../../common/firebase';

enum Status {
  STARTING,
  STOPPING,
  RUNNING,
  STANDBY,
  STOPPED,
  ERROR
}
interface Channel {
  arn: string,
  label: string,
  status: Status,
  manual?: boolean,
  updateTime: Date|FirebaseFirestore.Timestamp
}
export type ChannelContext = {
  collectionRef: CollectionReference<DocumentData>
};
export default () => {
  //
  let context: ChannelContext = {
    collectionRef: collection(db, 'resources')
  };

  return (
    <div>
      <h2>Streaming Channels</h2>
      <Outlet context={context}/>
    </div>
  )
}
