import { Route, Routes } from "react-router-dom";

import WeekendLayout from "./WeekendLayout";
import WeekendCreate from "./WeekendCreate";
import WeekendIndex from "./WeekendIndex";
import Weekend from "./Weekend";

export default function WeekendRoutes() {
  return (
    <Routes>
      <Route path="*" element={<WeekendLayout/>}>
          <Route index element={<WeekendIndex/>}/>
          {/* TODO : /new path, or modal w/ form, from Index? */}
          <Route path='new' element={<WeekendCreate/>}/>
          <Route path=':weekendId' element={<Weekend/>}/>
      </Route>
    </Routes>
  )
}
