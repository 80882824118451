import React, { useState, useEffect } from 'react';

export function CountdownTimer({toDate, onEnd, onEnding = () => false}) {
  // TODO: add image_url to challenge settings

  const getTimeLeft = function() {
    let now = new Date();
    let difference = +toDate - +now;

    let timeLeft = difference > 0 ? {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
        ended: false
    } : {ended: true};
    return timeLeft;
  }
  const [timeLeft, setTimeLeft] = useState(getTimeLeft());
  let hasPassed = timeLeft.ended === true;
  useEffect(() => {
    console.log('use - effect');
    if (hasPassed) {
      return;
    }
    const timer = setTimeout(() => {
      let currentTimeLeft = getTimeLeft();
      // if (currentTimeLeft.ended && onEnding) {
      //   onEnding();
      // }
      setTimeLeft(currentTimeLeft);
    }, 1000);
    return () => clearTimeout(timer);
  });

  useEffect(() => {
    if (hasPassed && onEnd) {
      onEnd()
    }
  }, [hasPassed]);

  return (
    <>
    <div className="">{timeLeft.days || 0}:{(timeLeft.hours || 0).toString().padStart(2, '0')}:{(timeLeft.minutes || 0).toString().padStart(2, '0')}:{(timeLeft.seconds || 0).toString().padStart(2, '0')}</div>
    {/* <div className="flex justify-center gap-4">
      <div>
        <div className="text-xl font-extrabold">{timeLeft.days || 0}</div>
        <div className="text-s font-light">Day{timeLeft.days !== 1 && 's'}</div>
      </div>
      <div>
        <div className="text-xl font-extrabold">{timeLeft.hours || 0}</div>
        <div className="text-s font-light">Hour{timeLeft.hours !== 1 && 's'}</div>
      </div>
      <div>
        <div className="text-xl font-extrabold">{timeLeft.minutes || 0}</div>
        <div className="text-s font-light">Minute{timeLeft.minutes !== 1 && 's'}</div>
      </div>
      <div>
        <div className="text-xl font-extrabold">{timeLeft.seconds || 0}</div>
        <div className="text-s font-light">Second{timeLeft.seconds !== 1 && 's'}</div>
      </div>
    </div> */}
    </>
  )
}