import React, { useState, useEffect } from "react";


export default function NotFound(props) {
  //
  return (
    <div>
      Not found...
    </div>
  )
}
