import { useState, useEffect } from 'react';
import { collectionGroup, collection, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth, functions } from '../../../common/firebase';

// TODO: separate into component
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { channelIcons, serviceIcons } from './EventDetail';
import { StatusIndicator } from '../../../components/EventStatus';
import MediaLiveChannel from '../../../components/events/MediaLiveChannel';

function EventListItem({event, isNext}) {
  let startTime = moment(event.start_time.toDate());
  let minutesToActual = event.add_minutes && typeof event.add_minutes === 'number' ? event.add_minutes : 11;
  let actualStart = moment(startTime).add(minutesToActual, 'minute');
  return (
    <Link to={`/admin/events/${event.id}`} className={`event-status-${event.status} my-2 py-1 no-underline px-2 border-b text-sm block drop-shadow ${isNext && 'next-event-item'}`}>
      <div className="flex justify-between py-1 items-center text-center">
        <div>{startTime.format('hh:mm:ssa')}</div>
        <div>{actualStart.format('ddd, h:mma')} experience</div>
        <div className="text-xs"><StatusIndicator status={event.status}/></div>
      </div>
      <div className="mb-4 mt-2 text-center text-xs">{event.title}</div>
      <div className="flex gap-6 justify-center mt-1">
        {event.outputs.filter(output => serviceIcons[output.service]).map(output => {
          return (
          <div key={output.name} className="flex gap-1">
            <img src={channelIcons[output.options.channel]} style={{width: '16px'}} className="rounded"/>
            <img src={serviceIcons[output.service]} style={{width: '16px'}} className="rounded"/>
          </div>
          );
        })}
      </div>

    </Link>
  )
}
// TODO: save events in firestore indepenent of external calls
//  "publish" from this system to map to API endpoints
function AdminEventsListView() {
  //
  const [title, setTitle] = useState('Live Stream');
  const [description, setDescription] = useState('Elevation Church');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState(moment().add('1', 'hour').format('HH:mm:ss'));
  const [thumbnailUrl, setThumbnailUrl] = useState('https://elevation-online.s3.amazonaws.com/broadcast/images/on_air_splash.jpg');
  const [fbToken, setFbToken] = useState('');

  const navigate = useNavigate();

  let [events, setEvents] = useState([]);
  let [filter, setFilter] = useState('');
  useEffect(() => {
    let eventsQuery = query(
      collection(db, 'events'),
      where('start_time', '>=', moment().subtract('16', 'hour').toDate()),
      where('start_time', '<', moment().add('2', 'day').toDate()),
      orderBy('start_time', 'asc')
    );
    let unsubscribe = onSnapshot(eventsQuery, (snaps) => {
      // snaps.docs.filter()
      let newEvents = snaps.docs.map(doc => ({id: doc.id, ...doc.data()}));
      setEvents(newEvents);
      console.log('events', newEvents);
    });
    return unsubscribe;
  }, []);

  // const handleSubmit = function(e) {
  //   e.preventDefault();
  //   let startUtc = moment(`${startDate}T${startTime}`).unix();
  //   let submitData = {
  //     title,
  //     description,
  //     startUtc,
  //     thumbnailUrl,
  //   };
  //   console.log('submitted', submitData);
  //   let createFbEventFn = httpsCallable(functions, 'postToFacebook');
  //   let createYtEventFn = httpsCallable(functions, 'postToYoutube');
  //   let createLiveEvent = httpsCallable(functions, 'createLiveEvent');
  //   return Promise.all([
  //     createFbEventFn({fbToken, ...submitData}),
  //     createYtEventFn({...submitData}),
  //   ]).then(([fb, yt]) => {
  //     console.log('results', fb, yt);
  //     return createLiveEvent({youtubeKey: yt.data.streamKey, facebookKey: fb.data.streamKey});
  //   });
  // };

  const viewEvent = function(id) {
    console.log('click', id);
    return navigate(`/admin/events/${id}`);
  }
  let now = moment();
  let todaysKey = now.format('YYYYMMDD');
  let nextEvent = events.find(e => moment(e.start_time.toDate()).isSameOrAfter(now));
  let endedEvents = events.filter(event => event.status === 'ended');
  let upcomingEvents = events.filter(event => event.status !== 'ended');

  let eventsByDay = events.reduce((result, event) => {
    let startTime = moment(event.start_time.toDate());
    let dayKey = startTime.format('YYYYMMDD');
    if (!result[dayKey]) {
      result[dayKey] = {events: [], label: startTime.format('dddd, MMM Do, YYYY')};
    }
    result[dayKey].events.push(event);
    return result;
  }, {});

  let dayKeys = Object.keys(eventsByDay);
  // console.log('days', dayKeys);
  return (
    <div className="px-2 min-h-screen">
      {dayKeys.map(dayKey => (
          <div key={dayKey} className="border-b border-gray-500 my-2">
            <div className="text-center text-2xl">{eventsByDay[dayKey].label}</div>
              <div className="max-w-3xl mx-auto">
              {eventsByDay[dayKey].events.map(eventItem => (
                <div key={eventItem.id}><EventListItem isNext={nextEvent && eventItem.id === nextEvent.id} event={eventItem} /></div>
              ))}
              </div>
          </div>
        )
      )}
      {/* {endedEvents.map((eventItem, index) => (
        <div key={eventItem.id} className="opacity-60"><EventListItem event={eventItem} /></div>
      ))}
      <div className="py-1">
        {upcomingEvents.length && <div>{upcomingEvents[0].status === 'live' ? 'Live Now' : 'Next Up'}
          <EventListItem isNext={upcomingEvents[0].status !== 'live'} event={upcomingEvents[0]}/>
        </div>}
      </div>
      <div>Upcoming</div>
      {upcomingEvents.slice(1).map((eventItem, index) => (
        <div key={eventItem.id}><EventListItem event={eventItem}/></div>
      ))} */}
    </div>
  );
}
export default AdminEventsListView;