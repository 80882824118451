import { initializeApp } from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB0VcQyJHBxxSzmMJluLQn1jaMg9aqUrpY",
  authDomain: "elevation.stream",
  projectId: "ec-live-stream",
  storageBucket: "ec-live-stream.appspot.com",
  messagingSenderId: "777577857139",
  appId: "1:777577857139:web:9f0b77654d9365838fea06"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp, 'us-east1');
const storage = getStorage(firebaseApp, 'us-east1');

if (window.location.hostname === 'localhost') {
  // TODO: check ports
  // connectFunctionsEmulator(functions, 'localhost', 5001);
  // connectAuthEmulator(auth, 'http://localhost:9099');
  // connectFirestoreEmulator(db, 'localhost', 8081);
}

export {
  db,
  auth,
  functions,
  firebaseApp,
  storage
};
