import React, { useState, useEffect } from 'react';
import { collectionGroup, collection, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth, functions } from '../../../common/firebase';
import { ECvLinkProvider } from '../../../providers/vlink.provider';

export default function() {
  //
  const schedulesRef = collection(db, 'weekends');

  const entitiesRef = collection(db, 'entities');

  let context = {schedulesRef};

  return (
    <ECvLinkProvider collectionRef={entitiesRef}>
      <div>
        <h2>Welcome to the schedule...</h2>
        <Outlet context={context}/>
      </div>
    </ECvLinkProvider>
  )
}