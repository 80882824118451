import React, { useState, useEffect } from 'react';

import { Routes, Route, Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useHistory } from 'react-router';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";

import { getAuth, signInWithPhoneNumber, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { db, auth } from '../../common/firebase';
import { Loading } from '../../common/utils';

function EcBtn({label, icon, disabled, isActive, onClick}) {
  //
  return (
    <div className={"flex flex-col items-center cursor-pointer" + (disabled ? ' opacity-50' : '')} onClick={onClick}>
      <div className={'fit-log-icon flex flex-col justify-center items-center w-12 h-12 rounded-full bg-gray-300 text-black'}>
        <FontAwesomeIcon icon={icon} size="lg"/>
      </div>
      <div className="font-extrabold mt-1">{label}</div>
    </div>
  );
}
export default function LoginForm({ onLogin }) {
  //
  const [authProvider, setAuthProvider] = useState(null);
  const [loading, setLoading] = useState(false);
  // TODO: add onError to props instead of putting error here.
  const [error, setError] = useState(null);

  useEffect(() => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account', hd: 'elevationchurch.org' });
    setAuthProvider(provider);
  }, []);

  const handleLogin = function() {
    if (!authProvider || loading) {
      return;
    }
    setLoading(true);
    return signInWithPopup(auth, authProvider).then(result => {
      console.log('logged in!', result);
      return onLogin();
    }).catch((err) => setError(err)).finally(() => setLoading(false));
  };

  return (
    <>
    <div className="flex flex-col w-96 px-4">
      <div>
        {/* <div className="text-lg font-bold">Login Required</div> */}
        <div className="text-sm leading-5 font-medium text-rose-500">{error ? (error.message || 'An error occurred') : ''}</div>
      </div>
      <div className="py-6">
        {/* { loading ? <Loading style={{height: '50px'}} active={loading}/> : ''} */}
        <div>
          <EcBtn label="Login with Google" icon={faGoogle} disabled={loading} onClick={handleLogin}/>
        </div>
      </div>
    </div>
    </>
  );
}
