
export const STATUS_LABELS = {
  pending: 'Upcoming Event',
  outputs_preparing: 'Preparing Social Platforms',
  outputs_prepared: 'Social Platforms Prepared. Waiting...',
  aws_preparing: 'Preparing Streams',
  aws_prepared: 'Stream Prepared... Waiting...',
  starting: 'Connecting streams...',
  active: 'Streams connected. Waiting...',
  going_live: 'Streams will go live soon...',
  live: 'Experience is Live!',
  ending: 'Experience preparing to End',
  ended: 'Experience Ended',
}

export function StatusIndicator({status}) {
  return <div>{STATUS_LABELS[status] || status}</div>;
}
export default function EventStatus(event) {
  return (
    <div>
      <StatusIndicator status={event.status}/>
    </div>
  )
}
