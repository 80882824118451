import React, { useState, useEffect, useRef } from 'react';
import { collectionGroup, collection, doc, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth, functions } from '../../../common/firebase';

// TODO: separate into component
import { httpsCallable } from 'firebase/functions';
import moment from 'moment';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import Hls from 'hls.js';
import { STATUS_LABELS } from '../../../components/EventStatus';
import { CountdownTimer } from '../../../components/CountdownTimer';
import MediaLiveChannel from '../../../components/events/MediaLiveChannel';
import { useProfile } from '../../../providers/profile.provider';

export const serviceIcons = {
  youtube: 'https://www.youtube.com/s/desktop/451d4225/img/favicon_32x32.png',
  facebook: 'https://facebook.com/favicon.ico',
  instagram: 'https://static.cdninstagram.com/rsrc.php/v3/yR/r/lam-fZmwmvn.png',
};
export const serviceLabels = {
  youtube: 'YouTube',
  facebook: 'Facebook',
  instagram: 'Instagram',
  ott: 'OTT',
};
export const channelIcons = {
  ec: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/ec-logofull.png',
  plus: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/eplus.jpg',
  prod: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/freshservice/icons/prod-icon.jpg',
  bp: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/freshservice/icons/prod-icon.jpg',
  psf: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/psf.jpg',
  ew: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/worship-icon.jpg',
  yth: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/yth-logo.jpg',
  central: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/pulse-yt-icon.png',
  staff: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/pulse-yt-icon.png',
  hf: 'https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/hf.jpg',
};
export const channelLabels = {
  ec: 'Elevation Church',
  prod: 'Production [Test]',
  bp: 'Facebook Test [Test]',
  plus: 'Elevation Plus',
  psf: 'Steven Furtick',
  ew: 'Elevation Worship',
  yth: 'Elevation YTH',
  central: 'Elevation Central',
  staff: 'Elevation Staff',
  hf: 'Holly Furtick',
};

function EmbedFromOutput(output) {
  if (output.service === 'youtube' && output.broadcast) {
    // return output.broadcast.contentDetails.monitorStream.embedHtml.replace('livemonitor=1', '');
    return (
      <div className="preview-iframe">
        <iframe width="425" height="344" src={`https://www.youtube-nocookie.com/embed/${output.broadcast.id}?autoplay=1&mute=1&vq=medium`} title={output.name} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </div>
    );
  } else if (output.service === 'facebook') {
    return (<div className="preview-iframe" dangerouslySetInnerHTML={{__html: output.embed_html}}></div>);
  }
  return '';
}

// function FacebookPreview({output}) {
//   let ref = useRef(null);
//   useEffect(() => {
//     // console.log('player', output);
//     const video = ref.current;
//     const player = dashjs.MediaPlayer().create();
//     // TODO: stream has to be active
//     player.initialize(video, output.dash_preview_url, true);
//     return () => {
//       player.reset();
//     }
//   }, [output.dash_preview_url]);
//   return (<video ref={ref}></video>)
// }
function EcIcon({url}) {
  return <img src={url} className="rounded"/>
}
function OutputLabel({output, status}) {
  let statusLabel = STATUS_LABELS[status] || 'Waiting...';
  return (
    <div className="output-status">
      {statusLabel}
    </div>
  );
}
function EmbedPlaceholder({output, event}) {
  let thumbnail = output.options.thumbnail || event.thumbnail_url || '';
  return (
    <div className="output-placeholder" style={{backgroundImage: `url(${thumbnail})`}}>
      <OutputLabel output={output} status={event.status}/>
    </div>
  )
}
function OutputPreview({output, event, stats}) {
  // let embedHtml = getEmbedFromOutput(output);
  // if (output.service === 'facebook' && output.dash_preview_url) {
    // return FacebookPreview(output);
  // }
  let serviceLogo = output.service && serviceIcons[output.service] || null;
  let serviceLabel = output.service && serviceLabels[output.service] || output.service;
  let channelLogo = output.options.channel && channelIcons[output.options.channel] || null;
  let channelLabel = output.options.channel && channelLabels[output.options.channel] || output.name;
  let shouldShowEmbed = ['live', 'ending'].includes(event.status) || (output.service !== 'facebook' && ['active', 'scheduled', 'going_live'].includes(event.status));
  let intFormat = new Intl.NumberFormat('en-US');
  return (
    <div className="py-2">
      {/* <h3>{output.name}</h3> */}
      <div className="flex justify-between items-center py-1">
        {channelLogo && <img src={channelLogo} className="rounded" style={{width:'32px'}}/>}
        {shouldShowEmbed && (<>
          <div>{(intFormat.format(stats.viewers || 0) + ' viewers')}</div>
          <div>{(intFormat.format(stats.peakLive || 0) + ' peak live')}</div>
        </>)}

        {serviceLogo && <img src={serviceLogo} className="rounded" style={{width:'32px'}}/>}
      </div>
      <div>
        {shouldShowEmbed ? <EmbedFromOutput {...output}/> : <EmbedPlaceholder output={output} event={event}/>}
      </div>
      <div className="text-sm">{channelLabel} - {serviceLabel}</div>
    </div>
  )
}
function EventPendingView({event}) {
  return <div><strong>{event.title}</strong> </div>
}
function HLSPreview({url}) {
  let ref = useRef(null);
  useEffect(() => {
    if (!ref.current) {
      return
    }
    const video = ref.current;
    const hls = new Hls();
    hls.attachMedia(video);
    hls.once(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(url);
    });
    return () => {
      // player.reset();
    }
  }, [url, ref.current]);
  return (<video ref={ref} controls autoPlay muted></video>)
}
function AdminEventDetailView(props) {
  //
  let [profile, , ] = useProfile();
  let {eventId} = useParams();
  let [event = {}, loading, error] = useDocumentData(doc(collection(db, 'events'), eventId));
  let [started, setStarted] = useState(false);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error!</div>;
  }
  // TODO: useeffect on event status?
  let testMode = window.location.search.match('test');
  if (!testMode && !(['active', 'scheduled', 'live', 'ending', 'going_live'].includes(event.status))) {
    // return <div><strong>{event.title}</strong> </div>
  }
  let hlsUrl = 'https://d1l0mq8050ivkk.cloudfront.net/out/v1/e6cd690f157845f6a2f922e488ab6107/index.m3u8';
  // https://elevationlive1.akamaized.net/hls/live/2016857/live1/playlist.m3u8
  // let hlsUrl = 'https://547f72e6652371c3.mediapackage.us-east-1.amazonaws.com/out/v1/eae6e0f789c34c77886d54114bc55f9c/index.m3u8';
  // let html = '<iframe allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowfullscreen="true" frameborder="0" height="360" scrolling="no" src="https://www.facebook.com/plugins/video.php?width=640&href=https%3A%2F%2Fwww.facebook.com%2F124649790463842%2Fvideos%2F508828167731052" style="border:none;overflow:hidden" width="640"></iframe>';
  const eventStarted = () => {
    console.log('event started');
  }
  let statsArray = (event.stats || []);
  let statsByOutput = statsArray.reduce((allStats, outputStats) => {
    let viewers = outputStats.viewers || 0;
    let peakLive = outputStats.peakLive || 0;
    allStats[outputStats.name] = {viewers, peakLive};
    return allStats;
  }, {});
  let totalViewers = statsArray.reduce((total, stat) => ((stat.viewers || 0) + total), 0);
  let peakLive = statsArray.reduce((peak, stat) => ((stat.peakLive || 0) + peak), 0);
  let outputGroups = event.outputs.reduce((groups, output) => {
    if (!groups[output.service]) {
      groups[output.service] = [];
    }
    groups[output.service].push(output);
    return groups;
  }, {youtube: [], facebook: []});
  // console.log('stats', statsByOutput, totalViewers);
  // return <FacebookPreview output={outputGroups.facebook[1]}/>
  let intFormat = new Intl.NumberFormat('en-US');
  // TODO: only for specific users
  let showInstagram = !!profile.info.ig;
  let showMCs = !!profile.info.mcs;
  let showDCDC = !!profile.info.dcdc;
  return (
    <div className="max-w-7xl mx-auto text-center">
      <div className="mb-1 grid grid-cols-6">
        <div className="font-bold">{STATUS_LABELS[event.status] || 'Waiting...'}</div>
        <div className="col-span-4">
          <div className="text-3xl mb-1">{intFormat.format(peakLive)} Peak Live</div>
          <div className="text-1xl mb-1">{intFormat.format(totalViewers)} Watching Now</div>
        </div>
        <div className="font-bold">
          {!started && <CountdownTimer toDate={event.start_time.toDate()} onEnd={() => setStarted(true)}/>}
          {started && <div>{moment(event.start_time.toDate()).format('hh:mm:ssa')}</div>}
        </div>
      </div>
      <div className="text-center text-xs"><strong>{event.title}</strong></div>
      {/* <div className="preview-iframe" dangerouslySetInnerHTML={{__html: html}}></div> */}
      <div>
        <div className="flex flex-wrap justify-center gap-x-6">
          {event.outputs.filter(({service}) => ['youtube', 'facebook'].includes(service)).map(output => <OutputPreview key={output.name} output={output} event={event} stats={statsByOutput[output.name] || {viewers: 0, peakLive: 0}}/>)}
          {/* <div className="py-2" style={{width: '360px'}}>
            <div className=" py-1">
              <img src='https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/eco-icon.png' className="rounded" style={{width:'32px'}}/>
            </div>
            <div><HLSPreview url={hlsUrl}/></div>
            <div className="text-sm">ECO / Monitor</div>
          </div> */}
          {event.start_eco &&
          <div className="py-2" style={{width: '360px'}}>
            <div className="flex justify-between py-1">
              <img src='https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/eco-icon.png' className="rounded bg-black p-1" style={{width:'32px'}}/>
              <div className="flex justify-end gap-2">
                <img src='https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/alexa-blue.png' className="rounded bg-white" style={{width:'32px'}}/>
                <img src='https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/apple-tv-icon.png' className="rounded" style={{width:'32px'}}/>
                <img src='https://storage.googleapis.com/assets.ele.vc/elevation/tech/livestreams/icons/roku-icon.png' className="rounded" style={{width:'32px'}}/>
              </div>
            </div>
            <div><HLSPreview url={hlsUrl}/></div>
            <div className="text-sm">ECO &amp; OTT</div>
          </div>
          }

          {showInstagram && <>
            <div className="py-3 flex flex-col justify-between" style={{width: '360px'}}>
              <MediaLiveChannel channelArn={'arn:aws:medialive:us-east-2:277723430739:channel:4739390'}/>
            </div>
            <div className="py-3 flex flex-col justify-between" style={{width: '360px'}}>
              <MediaLiveChannel channelArn={'arn:aws:medialive:us-east-2:277723430739:channel:354932'}/>
            </div>
          </>}

          {showMCs && <>
            <div className="py-3 flex flex-col justify-between" style={{width: '360px'}}>
              <MediaLiveChannel channelArn={'arn:aws:medialive:us-east-2:277723430739:channel:3656328'} allowStop={true}/>
            </div>
            <div className="py-3 flex flex-col justify-between" style={{width: '360px'}}>
              <MediaLiveChannel channelArn={'arn:aws:medialive:us-east-1:277723430739:channel:6874987'} allowStop={true}/>
            </div>
          </>}
        </div>
          {showDCDC && <div className="flex flex-col items-center justify-center">
            <h1>DCDC</h1>
            <div className="p-4 border-white border-2" style={{width: '50%'}}>
              <MediaLiveChannel channelArn={'arn:aws:medialive:us-east-1:277723430739:channel:1170181'} allowStop={true} allowSave={false}/>
            </div>
          </div>}

        {/* <div className="flex flex-wrap justify-around my-1">
          {outputGroups.youtube.map(output => <OutputPreview key={output.name} output={output} event={event} stats={statsByOutput[output.name] || {viewers: 0, peakLive: 0}}/>)}
        </div>
        <div className="flex flex-wrap justify-around my-1">
          {outputGroups.facebook.map(output => <OutputPreview key={output.name} output={output} event={event} stats={statsByOutput[output.name] || {viewers: 0, peakLive: 0}}/>)}
        </div>
        <div className="flex flex-wrap justify-around my-1">
          <div style={{width: '360px'}}>
            <h1>ECO</h1>
            <div><HLSPreview url={hlsUrl}/></div>
          </div>
          <div style={{width: '360px'}}>
            <h3>OTT (AppleTV/Alexa/Roku)</h3>
            <div><HLSPreview url={hlsUrl}/></div>
          </div>
        </div> */}
        <div>
        {/* <iframe width="425" height="344" src="https://www.youtube.com/embed/YnxF_K_myIQ?autoplay=1&livemonitor=1&mute=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
        </div>
      </div>
      {/* <pre className="text-xs">{JSON.stringify(event, null, 2)}</pre> */}

    </div>
  )
}
export default AdminEventDetailView;
