import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, orderBy, query, setDoc, where } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useCollection } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import moment from 'moment';
import { useECvLink } from '../../../providers/vlink.provider';
import { ScheduleOption } from '../../../components/events/CronSchedule';

const defaultDate = moment().subtract(1, 'month').toDate();

export default function() {
  //
  const {schedulesRef} = useOutletContext();

  let [settings, , ] = useECvLink();

  let [showingForm, setShowingForm] = useState(false);
  let [filter, setFilter] = useState({date: defaultDate});

  const ref = query(schedulesRef, where('date', '>=', filter.date), orderBy('date', 'desc'));

  let [value, loading, error] = useCollection(ref);

  let navigate = useNavigate();

  if (loading) {
    return <FullscreenLoadingPage/>
  }

  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <ScheduleOption/>
      <h3>Schedule List</h3>
      {showingForm
        && <NewScheduleForm collectionRef={schedulesRef} onCreate={(newScheduleId) => navigate(newScheduleId)}/>
       || <button onClick={() => setShowingForm(true)}>+ New Schedule</button>}
      <div>
        {value.docs.map(snap => {
          let label = snap.get('key');
          return <div key={snap.id}><Link to={snap.id}>{label}</Link></div>
        })}
      </div>
    </div>
  )
}

function NewScheduleForm({collectionRef, onCreate}) {
  let [date, setDate] = useState(new Date());
  let [creating, setCreating] = useState(false);
  let [alreadyExists, setAlreadyExists] = useState(false);

  let key = moment(date).format('YYYYMMDD');

  // doesn't make sense in the schedule
  let [pastorPreaching, setPastorPreaching] = useState(false);

  let [label, setLabel] = useState(key);
  let ref = doc(collectionRef, key)

  const createSchedule = async () => {
    if (creating) {
      return;
    }
    setCreating(true);
    let currentDoc = await getDoc(ref);
    if (currentDoc.exists()) {
      return setAlreadyExists(true);
    }
    await setDoc(ref, {date, key, pastorPreaching});
    return onCreate(key);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await createSchedule();
  };

  return (
    <div>
      {alreadyExists && <div>A schedule already exists for the selected date. <Link to={key}>Click Here</Link> to view</div>}
      <form onSubmit={(event) => handleSubmit(event)}>
        <label>
          Schedule Label
          <input type="text" className="form-input" name="label" value={label} onChange={(e) => setLabel(e.target.value)}/>
        </label>
        <label>
          Pastor Preaching
          <input type="checkbox" checked={pastorPreaching} onChange={(e) => setPastorPreaching(!!e.target.checked)}/>
        </label>
        <button type="submit" disabled={creating}>Create</button>
      </form>
    </div>
  );
}
