import React, { useState, useEffect } from 'react';
import { collection, doc } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { useOutletContext, useParams } from 'react-router-dom';


export default function WeekendCreate() {
  //
  const {weekendsRef} = useOutletContext();
  let [busy, setBusy] = useState(false);
  let [error, setError] = useState(null);


  if (error) {
    console.error(error);
    return <ErrorPage {...error}/>;
  }

  return (
    <div>
      <h3>Weekend create</h3>
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </div>
  )
}
