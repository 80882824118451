import React, { useState, useEffect } from 'react';
import { collectionGroup, collection, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth, functions } from '../../../common/firebase';

export default function WeekendLayout() {
  //
  const weekendsRef = collection(db, 'weekends');

  let context = {weekendsRef};

  return (
    <div>
      <h2>Welcome to the Weekend...</h2>
      <Outlet context={context}/>
    </div>
  )
}