
import React, { useState, useEffect } from "react";

import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';

// import fitIconBlack from '../../static/img/fit-logo-black.png';
import { LoadingPage } from '../../common/utils';
import Nav from './Nav';


export default function Header({ profile }) {
  //
  return (
    <header className="flex justify-between items-center">
      <div className="w-1/3">&nbsp;</div>
      <div className="w-[32px]"></div>
      <div className="w-1/3 text-right">
        {/* TODO: profile picture/drop-down menu */}
        {/* <Nav profile={ profile }/> */}
      </div>
    </header>
  )
}

