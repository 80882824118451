import { useEffect, useCallback, useMemo, useState, useReducer, createContext, useContext } from 'react';
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import { db, auth, functions } from '../common/firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';

const ECvLinkContext = createContext(null);

export function useECvLink() {
  return useContext(ECvLinkContext);
}

export function ECvLinkProvider({children, collectionRef, initialValue = null}) {
  const [settings, setSettings] = useState(initialValue);

  useEffect(() => {
    if (!collectionRef) {
      return;
    }
    return onSnapshot(collectionRef, (snaps) => {
      let data = snaps.docs.map(snap => snap.data());
      setSettings(data);
    });
  }, [collectionRef]);

  let result = [{settings}];
  return <ECvLinkContext.Provider value={result}>{children}</ECvLinkContext.Provider>;
}
