import React, { useState, useEffect, useContext } from 'react';
import { collection, doc } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { useParams } from 'react-router-dom';


// TODO:
// decide
//   "Edit" buttons inline?
//   or a full "edit" page?
// ----
//   We're connecting this to events (sub-collections)
//   so, to avoid
export default function() {
  // const {weekendsRef} = useContext();
  const {id} = useParams();

  const ref = doc(db, `weekends/${id}`);
  let [value, loading, error] = useDocumentData(ref);

  if (loading) {
    return <FullscreenLoadingPage/>
  }

  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <h3>Schedule Detail</h3>
      {/* ScheduledEvent component */}
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </div>
  )
}
