// import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { LoadingPage, FullscreenLoadingPage, ErrorPage } from '../common/utils';
import { useProfile } from './profile.provider';

function checkAuth(profile) {
  return profile && profile.isLoggedIn;
}
function checkProfile(profile, isRequired = true) {
  return !isRequired || (profile && profile.exists);
}
function checkRegistration(profile, isRequired = true) {
  return !isRequired || (profile && profile.isRegistered);
}

function RequireAuth({ children, andProfile = true, andRegistration = false }) {
  let [profile, loading, error] = useProfile();
  let location = useLocation();

  if (loading) {
    // console.error('RequireAuth loading');
    return <FullscreenLoadingPage/>;
  }
  if (error) {
    // console.error('RequireAuth error', error);
    return <ErrorPage message="Auth error"/>;
  }
  // TODO: login and exists are basically synonymous..
  if (!checkAuth(profile)) {
    // console.error('RequireAuth checkAuth = false');
    // TODO: redirect to previous location
    return <Navigate to="/user/login" state={{ from: location }} replace />;
  }
  if (!checkProfile(profile, andProfile)) {
    // console.error('RequireAuth checkProfile = false');
    return <Navigate to="/user/logout" replace />;
  }
  if (!checkRegistration(profile, andRegistration)) {
    // console.error('RequireAuth checkRegister = false', profile);
    return <Navigate to="/user/register" replace />;
  }
  return children;
}

export {
  RequireAuth
};
