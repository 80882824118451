import React, { useState, useEffect, useContext } from 'react';
import { collection, doc } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { useParams } from 'react-router-dom';


export default function Weekend() {
  // const {weekendsRef} = useContext();
  const {weekendId} = useParams();

  const ref = doc(db, `weekends/${weekendId}`);
  let [value, loading, error] = useDocumentData(ref);

  if (loading) {
    return <FullscreenLoadingPage/>
  }
  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <h3>Weekend Detail</h3>
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </div>
  )
}
