import { useState } from "react";

export function ScheduleOption() {
  //
  let [minute, setMinute] = useState({});
  let [hour, setHour] = useState({});

  return (<>
  <div><ScheduleMinute value={minute} setValue={setMinute}/></div>
  <div><ScheduleHour value={hour} setValue={setHour}/></div>
    {/* Month */}
  </>)
}
function ScheduleMinute(props) {
  return <CronInput label="Minute" constraints={{min: 0, max: 59}} {...props}/>
}
function ScheduleHour(props) {
  return <CronInput label="Hour" constraints={{min: 0, max: 23}} {...props}/>
}
function CronInput({label, constraints, value, setValue}) {
  let {choice = '*', option = {}} = value;
  const handlers = {
    '*': CronEvery,
    '-': CronRange,
    ',': CronSpecific
  };
  let OptionHandler = handlers[choice];
  return (<>
  <select name="choice" value={choice} onChange={(e) => setValue({choice: e.target.value, option})}>
    <option value="*">Every</option>
    <option value="-">From...</option>
    <option value=",">Specifically...</option>
  </select>
  <OptionHandler label={label} constraints={constraints} value={option} setValue={(optionValue) => setValue({choice, option: optionValue})}/>
  </>);
}

function CronEvery({label, constraints = {min: 0, max: 0}, value = {}, setValue}) {
  return (<>
  {label}
  </>)
}

function CronRange({label, constraints = {min: 0, max: 0}, value, setValue}) {
  let {min, max} = constraints;
  let {startAt = min, endAt = max} = value || {};

  return (<>
    {label} <input type="number" min={min} max={max} value={startAt} onChange={(e) => setValue({startAt: e.target.value, endAt})}/>
    through <input type="number" min={min} max={max} value={endAt} onChange={(e) => setValue({startAt, endAt: e.target.value})}/>
  </>);
}

function CronSpecific({label, constraints = {min: 0, max: 0}, value = {}, setValue}) {
  return (<>
  {label}
  </>)
}