//
import './App.css';
import React, { useState, useEffect } from "react";

import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
// import { doc, onSnapshot } from "firebase/firestore";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { useHistory } from "react-router";

// import {functions, firebaseApp, db} from './common/firebase';

// import { Loading, LoadingPage } from './common/utils';
// import { ProfileProvider, useProfile } from './providers/profile.provider';

import Layout from './views/Layout';
import HomeView from './views/HomeView';
import NotFound from './views/errors/NotFound';

import LiveEvent from './views/live/LiveEvent';

import UserRoutes from './routes/user.routes';
import AdminRoutes from './views/admin/admin.routes';
import { RequireAuth } from './providers/auth.provider';

// import TestFn from './Test';

export default function AppRouter() {
  //
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomeView />} />
        <Route path="live" element={<RequireAuth><LiveEvent /></RequireAuth>}/>
        <Route path="user/*" element={<UserRoutes />}/>
        <Route path="admin/*" element={<AdminRoutes />}/>
        {/* <Route path="challenges/*" element={<ChallengeRoutes />}/> */}
        {/* TODO: /user/[login/register/profile/logout] */}
        {/* separate user component to handle sub-routes, then have that useProfile with loading check */}
        {/* <Route path="profile" element={<ProfilePage />} /> */}
        {/* <Route path="register" element={<RegisterPage profile={profile} />} /> */}
        {/* <Route path="challenges/*" element={<ChallengePage profile={profile} />} /> */}
        {/* <Route path="challenge/*" element={<ChallengeRoutes profile={profile} />} /> */}
        {/* <Route path="test" element={<TestPage />} /> */}
        {/* <Route path="login/*" element={<LoginPage />} /> */}
        {/* <Route path="logout" element={<Logout />} /> */}
        {/* <Route
          path="test/:testId"
          element={<TestParams />}
        /> */}
        {/* TODO: require auth in the Challenge page? */}
        {/* <Route path="Challenge" element={<Challenge />}></Route> */}
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}
