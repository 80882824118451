

export default function LiveEvent() {
  // Show currently live event, or a preview of the next live event
  // show "next up" somewhere (bottom bar? corner?)
  // link to /live/schedule for full list
  // show "there are no upcoming events" if not found
  //
  return (
    <div>Live Event</div>
  );
}
