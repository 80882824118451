import React, { useState, useEffect } from 'react';
import { CollectionReference, collection, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useCollection } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { Link, useOutletContext } from 'react-router-dom';
import moment from 'moment';

import { ChannelContext } from './ChannelLayout';

const defaultDate = moment().subtract(1, 'month').toDate();
export default () => {

  const {collectionRef} = useOutletContext<ChannelContext>();

  const ref = query(collectionRef, where('manual', '==', true));

  let [value, loading, error] = useCollection(ref);

  if (loading) {
    return <FullscreenLoadingPage/>
  }

  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <h3>Weekend List</h3>
      {/* TODO: autogenerate */}
      <div>Create a new Weekend</div>
      <div>
        {value.docs.map(snap => {
          let label = snap.get('label');
          return <div key={snap.id}><Link to={snap.id}>{label}</Link></div>
        })}
      </div>
    </div>
  )
}
