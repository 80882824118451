import React, { useState, useEffect } from 'react';
import { collectionGroup, collection, query, where, getDocs } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth } from '../common/firebase';

import { signOut } from 'firebase/auth';

import { useProfile } from '../providers/profile.provider';
// import RegistrationForm from '../components/user/RegistrationForm';
// import { UserLogin } from '../components/user/Login';
import LoginView from '../views/user/login/LoginView';
// import AdminView from '../views/user/admin/AdminView';
// import RegisterView from '../views/user/register/RegisterView';

import { RequireAuth } from '../providers/auth.provider';
// import ProfileLayout from '../views/user/profile/ProfileLayout';
// import ProfileInfoView from '../views/user/profile/ProfileInfoView';
// import ProfileEditView from '../views/user/profile/ProfileEditView';
import { FullscreenLoadingPage } from '../common/utils';

function LogoutView() {
  //
  const navigate = useNavigate();
  useEffect(() => {
    signOut(auth).then(() => {
      navigate('/user/login', {replace: true});
    })
  }, []);

  return <FullscreenLoadingPage/>;
}
function TestViewLiveStreams() {
  // <Outlet context={context}/>
  return (<div>Test View For Now</div>);
}
function ProfileRoutes() {
  //
  return (
    <RequireAuth>
      <Routes>
        <Route path="*" element={<TestViewLiveStreams/>}>
          <Route index element={<TestViewLiveStreams />}/>
          <Route path='edit' element={<TestViewLiveStreams />}/>
        </Route>
      </Routes>
    </RequireAuth>
  )
}

function UserRoutes() {
  // /user/*
  return (
    <Routes>
      <Route index element={<Navigate to='login' replace={true}/>}/>
      <Route path='login' element={<LoginView />}/>
      <Route path='logout' element={<LogoutView />}/>
      {/* <Route path='register' element={<RegisterView />}/>
      <Route path='admin' element={<AdminView />}/>
      <Route path='profile/*' element={<ProfileRoutes />}/> */}
    </Routes>
  );
}

export default UserRoutes;