import React, { useState, useEffect } from 'react';
import { doc } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useDocumentData } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { Link, useOutletContext, useParams } from 'react-router-dom';
import moment from 'moment';

import { ChannelContext } from './ChannelLayout';

const defaultDate = moment().subtract(1, 'month').toDate();
export default () => {
  const {id} = useParams();
  const {collectionRef} = useOutletContext<ChannelContext>();

  const ref = doc(collectionRef, id);
  let [value, loading, error] = useDocumentData(ref);

  if (loading) {
    return <FullscreenLoadingPage/>
  }

  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <h3>Channel List</h3>
      <div>
        {value.docs.map(snap => {
          let label = snap.get('label');
          return <div key={snap.id}><Link to={snap.id}>{label}</Link></div>
        })}
      </div>
    </div>
  )
}
