import { Route, Routes } from "react-router-dom";

import ChannelLayout from "./ChannelLayout";
// import WeekendCreate from "./WeekendCreate";
import ChannelIndex from "./ChannelIndex";
import Channel from "./Channel";

export default () => {
  return (
    <Routes>
      <Route path="*" element={<ChannelLayout/>}>
          <Route index element={<ChannelIndex/>}/>
          {/* TODO : /new path, or modal w/ form, from Index? */}
          {/* <Route path='new' element={<WeekendCreate/>}/> */}
          <Route path=':id' element={<Channel/>}/>
      </Route>
    </Routes>
  )
}
