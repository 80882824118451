import { useState, useEffect } from 'react';
import { collectionGroup, collection, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { Routes, Route, Link, Outlet, useLocation, useNavigate, Navigate, useParams } from 'react-router-dom';
import { db, auth, functions } from '../../common/firebase';

import { signOut } from 'firebase/auth';

import { useProfile } from '../../providers/profile.provider';
// import RegistrationForm from '../components/user/RegistrationForm';
// import { UserLogin } from '../components/user/Login';
import EventsListView from './events/EventsList';
import EventDetailView from './events/EventDetail';
// import RegisterView from '../views/user/register/RegisterView';

import { RequireAuth } from '../../providers/auth.provider';
// import ProfileLayout from '../views/user/profile/ProfileLayout';
// import ProfileInfoView from '../views/user/profile/ProfileInfoView';
// import ProfileEditView from '../views/user/profile/ProfileEditView';
import { FullscreenLoadingPage } from '../../common/utils';

import WeekendRoutes from './weekends/WeekendRoutes';
import ScheduleRoutes from './schedule/ScheduleRoutes';
import ChannelRoutes from './channel/ChannelRoutes';

function AdminLayoutView() {
  //
  const context = {admin: true};
  return (<div><Outlet context={context}/></div>);
}
function AdminHomeView() {
return <Navigate to="./events"/>;
  // return (<div className="center"><Link to="./events">Events</Link></div>);
}

function AdminRoutes() {
  // /admin/*
  return (
    <RequireAuth>
      <Routes>
        <Route path="*" element={<AdminLayoutView/>}>
          <Route index element={<AdminHomeView />}/>
          <Route path='events' element={<EventsListView />}/>
          <Route path='events/:eventId' element={<EventDetailView />}/>
          {/* IG as a route? or a component on the page? */}
          {/* Needs to be a component anyway. Do that first */}
          <Route path='schedule/*' element={<ScheduleRoutes/>}/>
          <Route path='weekends/*' element={<WeekendRoutes/>}/>
            <Route path='channels/*' element={<ChannelRoutes/>}/>
          {/* /admin/setup* */}
          <Route path="setup/*">
            <Route index element={<div>Setup Index</div>}/>
            <Route path='events' element={<div>Events</div>}/>
            {/* <Route path='outputs' element={<div>Outputs</div>}/> */}
          </Route>
        </Route>
      </Routes>
    </RequireAuth>
  );
}

export default AdminRoutes;
