import React, { useState, useEffect } from 'react';
import { collectionGroup, collection, doc, query, where, getDocs, onSnapshot, orderBy } from 'firebase/firestore';
import { db, auth, functions } from '../../common/firebase';

import { httpsCallable } from 'firebase/functions';
import { channelIcons, serviceIcons } from '../../views/admin/events/EventDetail';
// import { DEFAULT_DETAILS } from '../../../components/ContentHelpers';

export default function MediaLiveRtmp({settings, onUpdate, disabled = false}) {
  let [destination, setDestination] = useState({Url: '', StreamName: ''});

  useEffect(() => {
    setDestination({...destination, ...settings.Destination});
  }, [settings.Destination]);

  const handleUpdate = (data) => {
    let Destination = {...settings.Destination, ...data};
    return onUpdate({...settings, Destination});
  };

  return (
    <div className="flex flex-col text-left mb-4">
      <label className="mt-2"> Stream URL:</label>
      <input type="text" className="text-sm p-1 flex-grow" name="stream_url" value={destination.Url} disabled={disabled} onChange={(e) => handleUpdate({Url: e.target.value})}/>
      <label className="mt-6"> Stream Key:</label>
      <input type="text" className="text-sm p-1 flex-grow" name="stream_key" value={destination.StreamName} disabled={disabled} onChange={(e) => handleUpdate({StreamName: e.target.value})}/>
    </div>
  )
}
