import { Route, Routes } from "react-router-dom";

import {
  Layout,
  IndexPage,
  DetailPage,
} from './';

export default function() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
          <Route index element={<IndexPage/>}/>
          {/* <Route path='new' element={<CreatePage/>}/> */}
          <Route path=':id' element={<DetailPage/>}/>
      </Route>
    </Routes>
  )
}
