import React, { useState, useEffect } from 'react';
import { collection, orderBy, query, where } from 'firebase/firestore';
import { db } from '../../../common/firebase';

import { useCollection } from 'react-firebase-hooks/firestore';
import { ErrorPage, FullscreenLoadingPage } from '../../../common/utils';
import { Link, useOutletContext } from 'react-router-dom';
import moment from 'moment';

const defaultDate = moment().subtract(1, 'month').toDate();
export default function WeekendIndex() {
  //
  const {weekendsRef} = useOutletContext();
  let [filter, setFilter] = useState({date: defaultDate});
  const ref = query(weekendsRef, where('date', '>=', filter.date), orderBy('date', 'desc'));

  let [value, loading, error] = useCollection(ref);

  if (loading) {
    return <FullscreenLoadingPage/>
  }

  if (error) {
    console.error(error);
    return <ErrorPage {...error}></ErrorPage>;
  }

  return (
    <div>
      <h3>Weekend List</h3>
      {/* TODO: autogenerate */}
      <div>Create a new Weekend</div>
      <div>
        {value.docs.map(snap => {
          let label = snap.get('key');
          return <div key={snap.id}><Link to={snap.id}>{label}</Link></div>
        })}
      </div>
    </div>
  )
}
