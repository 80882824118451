import React, { useState, useEffect } from 'react';
import { collectionGroup, collection, doc, query, where, getDocs, onSnapshot, orderBy, updateDoc } from 'firebase/firestore';
import { db, auth, functions } from '../../common/firebase';

import { httpsCallable } from 'firebase/functions';
import { channelIcons, serviceIcons } from '../../views/admin/events/EventDetail';
// import { DEFAULT_DETAILS } from '../../../components/ContentHelpers';
import { useCollection, useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore';
import MediaLiveRtmp from './MediaLiveRtmp';
import moment from 'moment';

export default function MediaLiveChannel({channelArn, allowStop = false, allowSave = true}) {
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  let ref = doc(collection(db, 'resources'), channelArn);

  let [channel, loading, ] = useDocumentData(ref);
  // let [inputs, , ] = useCollectionData(collection(ref, 'inputs'));
  let [outputs, , ] = useCollection(collection(ref, 'outputs'));
  let [changedOutputs, setChangedOutputs] = useState({});
  // console.log(channel, loading, outputs);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!channel) {
    return <div>Nothing</div>;
  }
  const startChannel = function() {
    if (saving || channel.status !== 'STOPPED') {
      return false
    }
    setSaving(true);
    let startMediaLiveFn = httpsCallable(functions, 'admin-startMediaLive');
    return startMediaLiveFn({channelArn}).then((res) => {
      console.log('starting!!', res);
    }).catch(err => setError(err)).finally(() => setSaving(false))
  }
  const stopChannel = function() {
    if (saving || channel.status !== 'RUNNING') {
      return false
    }
    setSaving(true);
    let startMediaLiveFn = httpsCallable(functions, 'admin-stopMediaLive');
    return startMediaLiveFn({channelArn}).then((res) => {
      console.log('stopping!!', res);
    }).catch(err => setError(err)).finally(() => setSaving(false))
  }
  const handleSubmit = function(e) {
    e.preventDefault();
    if (saving) {
      return false
    }
    setSaving(true);
    let updateMediaLiveFn = httpsCallable(functions, 'admin-updateMediaLive');
    return saveDestinations().then(result => {
      return updateMediaLiveFn({channelArn});
    }).then((resp) => {
      console.log(resp.data, resp);
    }).catch(err => setError(err)).finally(() => setSaving(false))
    // return createEventFn(submitData).then(() => {
    //   // Done
    //   return true;
    // }).catch(err => setError(err)).finally(() => setSaving(false));
  };
  const updateOutput = (id, data) => {
    return setChangedOutputs({...changedOutputs, [id]: data})
  }
  const saveDestinations = () => {
    let saving = outputs?.docs.map(outputSnap => {
      let id = outputSnap.id;
      let outputData = outputSnap.data();
      let {Destination} = id in changedOutputs ? changedOutputs[id] : outputData;
      return updateDoc(outputSnap.ref, {Destination});
    }) || [];
    return Promise.all(saving).then(() => {
      return updateDoc(ref, {lastSaved: new Date()});
    });
  }
  let outputComponents = {
    rtmp: MediaLiveRtmp
  };
  // TODO: canChange only if update
  const isRunning = channel.status === 'RUNNING';
  const isStopped = channel.status === 'STOPPED' || channel.status === 'CREATED';
  const canChange = isStopped;// && moment(channel.updateTime?.toDate()).isBefore(moment().subtract(30, 'second'));
  const canSave = allowSave && !saving && Object.keys(changedOutputs).length > 0 && canChange;
  // does updateTime update when saving?
  const canStart = !saving && isStopped && (outputs?.docs.length === 0 || (channel.lastSaved && moment(channel.lastSaved?.toDate()).isAfter(moment().subtract(45, 'minute'))));
  const canStop = allowStop && isRunning;
  return (
    <>
      {error && <div>Error! {error}</div>}
      <div className="flex justify-between">{channel.label} <div className={channel.status === 'RUNNING' ? 'text-green-400' : 'text-orange-400'}>{channel.status}</div> <img src={serviceIcons[channel.service]} className="rounded" style={{width:'32px'}}/></div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="flex flex-col place-content-evenly">
          {outputs?.docs.map(outputSnap => {
            let id = outputSnap.id;
            let data = changedOutputs[id] || outputSnap.data();
            let Destination = outputComponents[data.type] || null;
            if (!Destination) {
              return <div>NotImplemented</div>
            }
            return <Destination key={id} settings={data} disabled={!canChange} onUpdate={(updated) => updateOutput(id, {...data, ...updated})}/>
          })}
        </div>
        <div className="mt-3 flex justify-between">
          <button type="submit" className={`btn btn-blue ${!allowSave ? 'opacity-0' : saving ? 'opacity-5' : ''}`} disabled={!canSave}>{saving ? 'Saving...' : 'Save'}</button>
          <button type="button" className={`btn bg-green-600 rounded ${!canStart ? 'opacity-30' : ''}`} disabled={!canStart} onClick={() => canStart ? startChannel() : null}>Start</button>
          {/* {canChange && !canSave && <button type="button" className="btn bg-green-600 rounded" onClick={}>Start</button>} */}
          {canStop && <button type="button" className={`btn bg-red-600 rounded ${!canStop ? 'opacity-30' : ''}`} disabled={!canStop} onClick={() => stopChannel()}>Stop</button>}
          {/* {!canChange || canSave && <button type="button" className="btn bg-gray-600 opacity-50 rounded">Not Ready</button>} */}
        </div>
        {/* load status from firestore */}
        {/* show start/stop btns (track status in firestore) */}
      </form>
    </>
  )
}
